import React from 'react';
import ProjectCard from './ProjectCard';
const ProjectsGrid = ({projects}) => (
  <div className="w-100 flex-l pl4 pr4 pl5-l pr5-l items-center flex-wrap justify-between mw11 center">
    {projects.map(project => (
      <ProjectCard project={project.node} image={project.node.data.portal_image} className="w-100 w-50-l pt3 pb3 pa5-l project-card grid-view mt4 mb4" />
    ))}
  </div>
);

export default ProjectsGrid;
