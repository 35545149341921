import React from 'react';
import { graphql } from 'gatsby';

import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/PageHeader';
import ProjectsGrid from '../components/projects/ProjectsGrid';

const UnderConstructionProjectsPage = ({
  data: { prismicProjectsUnderConstruction, projects },
}) => {
  const { data } = prismicProjectsUnderConstruction;

  return (
    <Layout headerColor='dark'>
      <SEO title={data.page_title.text} />
      <div className='page-spacer db' />
      <PageHeader title={data.page_title} subTitle={data.sub_title} />
      <ProjectsGrid projects={projects.edges} />
    </Layout>
  );
};

export default withPreview(UnderConstructionProjectsPage);

export const pageQuery = graphql`
  query {
    prismicProjectsUnderConstruction {
      data {
        page_title {
          text
        }
        sub_title {
          text
        }
      }
    }
    projects: allPrismicUnderConstruction(
      sort: { fields: [data___page_weight], order: ASC }
    ) {
      edges {
        node {
          uid
          type
          data {
            project_name {
              text
            }
            subheading {
              text
            }
            portal_image {
              fluid {
                aspectRatio
                ...GatsbyPrismicImageFluid
              }
            }
            project_color
            page_weight
          }
        }
      }
    }
  }
`;
