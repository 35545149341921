import React from 'react';
import FadeUp from './transitions/FadeUp';

const PageHeader = ({ title, subTitle }) => {
  return (
    <div className="pl4 pr4 pl5-l pr5-l pt5 pb5 pt6-l pb6-l tl tc-l">
      <FadeUp>
        <h1 className="f2 f1-l tiempos mt0 mb0">
          <span className="gold">{title.text} </span>/ {subTitle.text}
        </h1>
      </FadeUp>
    </div>
  );
};

export default PageHeader;
